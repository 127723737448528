import { Box, Heading, useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import bgVideoDefault from './comp_eclass.mp4'
import { bgVideoLoginImage as bgImageDefault } from '../../../assets'

type BrandingProps = {
  videoCostumer?: string
  imageCostumer?: string | null | undefined
  text?: string
  colorText?: string
  showText: boolean
}

export const Branding = ({
  videoCostumer,
  imageCostumer,
  text,
  colorText,
  showText,
}: BrandingProps) => {
  const { t } = useTranslation('login')

  const [isSmall] = useMediaQuery('(max-width: 915px)')
  if (isSmall) {
    return <></>
  }

  return (
    <Box as="section" position="relative" display="grid" placeContent="center" h="100%">
      <Box
        position={'absolute'}
        as="video"
        autoPlay
        minH="100vh"
        muted
        loop
        objectFit="cover"
        objectPosition="center"
        poster={imageCostumer ?? bgImageDefault}
        src={imageCostumer && !videoCostumer ? imageCostumer : (videoCostumer ?? bgVideoDefault)}
        w="100%"
        h="100%"
      />

      <Heading
        fontWeight={400}
        zIndex={1}
        color={colorText ?? '#fff'}
        fontSize="36px"
        lineHeight="normal"
        mb={0}
        w="365px"
      >
        {text === '' || showText ? t('LogInStart') : text}
      </Heading>
    </Box>
  )
}
