import { useState, useEffect } from 'react'
import { Box, Flex, Text, useMediaQuery, Divider } from '@chakra-ui/react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Branding } from './Branding'
import { BrandingLogos, LoadingScreen, Footer } from '../../components'
import { Form } from './Form/Form'
import { GET_LOGIN_TYPES, GET_PORTAL_FOOTER, GET_PROGRAM_NOTICES } from '../../services'
import { GetPortal } from './getPortalTypes.d'
import { Sso } from './Sso'
import { Notification } from './Notification'
import { updateFavicon } from '../../utils/setFavicon'
import { handleErrorSentry } from '../../utils'
import { WithSignup } from './WithSignup'
import isWindowClose from '../../hooks/useWindowClosed'

/*
 * Login component
 * Este componente representa la vista del logueo y dispara las querys necesarias para la carga
 * Manteniendo la animacion de carga mientras se obtienen los datos
 */

interface DataPortalProps {
  data: GetPortal | null
  loading: boolean
}

export const Login = () => {
  const [isDesktop] = useMediaQuery('(min-width: 932px)')
  const [dataPortal, setDataPortal] = useState<DataPortalProps>({
    data: null,
    loading: true,
  })

  const [getLoginTypes, { loading: loadingLoginTypes }] =
    useLazyQuery(GET_LOGIN_TYPES)
  const [getPortalFooter, { loading: loadingPortalFooter }] =
    useLazyQuery(GET_PORTAL_FOOTER)
  const [
    getProgramNotices,
    { loading: loadingProgramNotices, data: dataProgramNotices },
  ] = useLazyQuery(GET_PROGRAM_NOTICES)

  const { t } = useTranslation('login')
  const navigate = useNavigate()
  // const refer = document.referrer
  const refer = new URLSearchParams(window.location.search).get('url')
  // const refer = 'https://testempresa1000.eclass.com'

  const spInitSSO = (portalId, ssoId, campusId) => {
    // Validacion para walmart que debe pasar por el otro dominio
    // Si se integran mas portales con ese dominio, se debe refactorizar para no quedar en duro
    const baseDomain =
      process.env.NODE_ENV === 'production'
        ? portalId === 868
          ? 'https://spcvesp.eclass.com'
          : 'https://spcv.eclass.com'
        : 'http://localhost:4004'
    const url = `${baseDomain}/sso/spinitsso-redirect-cv?id=${ssoId}&portal=${portalId}&campus=${campusId}`
    window.location.replace(url)
  }

  const getPortal = () => {
    // Ser agrega cookie para CampusVirtual
    // const validateRefer = refer.replace('login', '')
    const host = import.meta.env.PROD ? `${refer}/api/1.0/portal` : '/api'
    fetch(host, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((response) => {
        // Validacion SSO
        const dataPortal = response.data.getPortal
        if (
          dataPortal.PortalSso &&
          dataPortal.PortalSso.length === 1 &&
          !dataPortal.Portal.showStandardLogin
        ) {
          spInitSSO(dataPortal.Portal.id, dataPortal.PortalSso[0].id, dataPortal.Portal.campusId)
          return
        }
        document.cookie = `CampusVirtual${dataPortal.Portal.id}=eclass`
        setDataPortal({
          ...dataPortal,
          data: response.data.getPortal,
          loading: false,
        })
      })
      .catch((err) => {
        // console.error('Error fetching data: ', err)
        // Prueba para validar si se cierra la ventana el usuario
        if (refer && !isWindowClose) {
          handleErrorSentry(
            Array.isArray(err) ? err[0] : err,
            'warning',
            'Login',
            'getPortal',
            'Error al consultar getPortal',
            'Error getPortal',
          )
        }
        navigate('/Oops')
      })
  }

  useEffect(() => {
    if (dataPortal.loading) {
      // Redirecciona a dashboard si ya tiene una sesion activa basado en UserSession de la cookie
      const cookRedirect = document.cookie
        .split('; ')
        .filter((cook) => cook.includes('UserSession'))
      const isUserData = localStorage.getItem('userData')
      const isLoggedUser = localStorage.getItem('loggedUser')
      if (cookRedirect.length > 0 && isUserData && isLoggedUser) {
        // TODO: Cambio el redirectUrl para que redireccione a dashboard
        // window.location.href = redirectUrl + '/dashboard'
      }
      getPortal()
    } else {
      if (dataPortal.data && dataPortal.data.Portal && dataPortal.data.Portal.campusId) {
        getProgramNotices({
          variables: {
            ProgramNoticeInput: {
              campusId: Number(dataPortal.data.Portal.campusId),
            },
          },
        })
        getLoginTypes({
          variables: {
            LoginTypeInput: {
              portalId: Number(dataPortal.data.Portal.id),
            },
          },
        })
        getPortalFooter({
          variables: {
            PortalFooterInput: {
              portalId: Number(dataPortal.data.Portal.id),
            },
          },
        })
      }
    }
  }, [dataPortal])

  if (
    dataPortal.loading ||
    loadingLoginTypes ||
    loadingPortalFooter ||
    loadingProgramNotices ||
    !dataProgramNotices ||
    !dataPortal ||
    !dataPortal.data
  ) {
    return (
      <div>
        <LoadingScreen />
      </div>
    )
  }

  document.title = dataPortal.data.Portal.name + ' - Login'
  localStorage.setItem('lang', dataPortal.data.Portal.Idiom.bcp47)
  updateFavicon(dataPortal.data.Portal.favicon)

  const hasPortalSso =
    dataPortal.data?.PortalSso &&
    Array.isArray(dataPortal.data.PortalSso) &&
    dataPortal.data.PortalSso.length > 0

  const { ProgramNotices } = dataProgramNotices.ProgramNotices ?? false
  const PortalBranding =
    dataPortal &&
    dataPortal.data &&
    dataPortal.data.PortalBranding &&
    dataPortal.data.PortalBranding
      ? dataPortal.data.PortalBranding
      : null

  const portalId = Number(dataPortal.data.Portal.id)

  return (
    <Flex minH="100vh" p={0} m={0} w="100%">
      {ProgramNotices && ProgramNotices.length > 0 && (
        <Notification ProgramNotices={dataProgramNotices.ProgramNotices.ProgramNotices} />
      )}
      {isDesktop && (
        <Box flex="1">
          <Branding
            colorText={PortalBranding?.loginTextColor}
            imageCostumer={PortalBranding?.loginImage}
            showText={(PortalBranding && Array.isArray(PortalBranding)) || false}
            text={PortalBranding?.loginText}
          />
        </Box>
      )}
      <Box flex={isDesktop ? '1' : 'auto'} display="flex" justifyContent="center" width="100%">
        <Box width="100%" mb="30px">
          <BrandingLogos
            logos={PortalBranding?.PortalBrandingLogo}
            showText={(PortalBranding && Array.isArray(PortalBranding)) || false}
            text={PortalBranding?.loginText}
          />
          <Box display="flex" justifyContent="center">
            <Box px="25px" className="containerForm">
              {!isDesktop && <Divider color="#e6e6e6" opacity={1} />}
              <Text
                fontFamily="Roboto"
                fontSize={isDesktop ? '2xl' : 'xl'}
                fontWeight="bold"
                lineHeight="1"
                marginBottom="40px"
                marginTop={isDesktop ? '37px' : '24px'}
                textAlign="center"
                sx={{
                  textWrap: 'pretty',
                }}
              >
                {t('LogInLog')}
              </Text>
              <Box maxW={isDesktop ? '307px' : '100%'} w="100p%" mx="auto">
                {hasPortalSso && (
                  <Sso
                    campusId={dataPortal.data?.Portal.campusId}
                    itemsSso={dataPortal.data?.PortalSso}
                    showMessage={dataPortal.data?.Portal.showStandardLogin}
                  />
                )}
                {dataPortal && dataPortal.data && dataPortal.data.Portal.showStandardLogin && (
                  <Form
                    campusId={dataPortal && dataPortal.data ? dataPortal.data.Portal.campusId : 0}
                    portalId={dataPortal && dataPortal.data ? dataPortal.data.Portal.id : 0}
                  />
                )}
                {dataPortal.data.Portal.withSignup && (
                  <Box display="flex" justifyContent="center" marginTop="24px">
                    <WithSignup />
                  </Box>
                )}
                {portalId && <Footer portalId={portalId} showInfo />}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
