import { Box, useMediaQuery, Text, Center } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import Types from './BrandingLogos.types'

// Debe recibir por lo menos 1 de los dos, array de logo o un string de logo por defecto
export const BrandingLogos = ({
  showMessage = true,
  text,
  logos,
  showText,
}: Types.BrandingLogos) => {
  const { t } = useTranslation('login')
  const [isDesktop] = useMediaQuery('(min-width: 932px)')
  const defaultLogo = 'https://cdn-platform.eclass.com/static/media/eclass.5ed089e9.svg'

  if (logos?.length === 0 && !defaultLogo) {
    return null
  }

  // En espera para agregar analytics
  const analytics = () => {}
  return (
    <Box bgColor={showMessage ? '#fff' : 'transparent'} m="0 0 0">
      <Box
        alignItems="center"
        data-testid="brandingLogos"
        display="flex"
        gap="16px"
        justifyContent="center"
        onClick={analytics}
        paddingTop={logos && logos.length > 0 ? (isDesktop ? '43px' : '24px') : 0}
        paddingX="24px"
      >
        {logos && logos.length > 0 ? (
          <Center maxW="340px" h="70px">
            {logos.map((logo) => (
              <Box
                alt={logo.name}
                as="img"
                h="100%"
                key={logo.url}
                objectFit="contain"
                src={logo.url}
              />
            ))}
          </Center>
        ) : (
          <Box w="186px" h="103px">
            <figure>
              <img src={defaultLogo} alt="Logo Eclass" />
            </figure>
          </Box>
        )}
      </Box>
      {!isDesktop && showMessage && (
        <Text
          fontFamily="Roboto"
          fontSize="1.125rem"
          m="24px"
          maxW={'357px'}
          mx="auto"
          p={'0 24px'}
          textAlign="center"
          sx={{
            textWrap: 'pretty',
          }}
        >
          {text === '' || showText ? t('LogInStart') : text}
        </Text>
      )}
    </Box>
  )
}
