import { useState, ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import { Box, Button, FormControl } from '@chakra-ui/react'

import { LOGIN_MUTATION } from '../../../services/graphql'
import { SelectLogin, InputLogin, Recaptcha } from '../../../components'
import { AlertWarning } from '../../../components/Alert/AlertWarning'
import { LoaderAsync } from '../../../assets'
import { encryptData } from '../../../utils/ecrypto'

/*
 * Form component
 * Este componente representa el formulario de logueo
 * Valida los inputs y envia la peticion al servidor
 * Redirecciona al usuario en caso de exito
 */

import { useTranslation } from 'react-i18next'

export const Form = ({ campusId, portalId }) => {
  const [selectedOption, setSelectOption] = useState<string>('')
  const [documentNumber, setDocumentNumber] = useState<string>('')
  const [loadingError, setLoadingError] = useState({
    status: false,
    message: '',
  })
  const [password, setPassword] = useState<string>('')
  const [loginAttempts, setLoginAttempts] = useState(0)
  const [captcha, setCaptcha] = useState({ verified: false, captchaToken: '' })

  const [addLogin, { loading }] = useMutation(LOGIN_MUTATION)

  const { t } = useTranslation('login')
  const urlParams = new URLSearchParams(window.location.search)
  const system = urlParams.get('system')

  const handleSelectTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectOption(e.target.value)
  }

  // Capture the value of the Input field
  const handleDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentNumber(e.target.value)
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const signWithToken = async (token: string) => {
    // const refer = document.referrer
    const refer = new URLSearchParams(window.location.search).get('url')
    window.location.href = `${refer}/login/sign_with_token?token=${token}`
  }

  // Submit the form
  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoginAttempts((prev) => prev + 1)
    try {
      const response = await addLogin({
        variables: {
          LoginInput: {
            campusId: Number(campusId),
            password: await encryptData(password),
            system: system?.toUpperCase(),
            type: selectedOption,
            username: documentNumber,
            portalId: portalId,
            forceSecure: true,
            captchaToken: captcha.captchaToken,
          },
        },
      })
      if (!response.data.UserLogin.status.success) {
        setLoadingError({
          status: true,
          message: response.data.UserLogin.status.detail,
        })
      }
      if (response.data.UserLogin.status.success) {
        signWithToken(response.data.UserLogin.token)
      }
    } catch (error) {
      console.log('Error during login', error)
    }
  }

  const optionTextInput = () => {
    if (selectedOption === '') {
      return t('LogInDocument')
    } else {
      return selectedOption
    }
  }

  const optionPlaceholderInput = () => {
    if (selectedOption === '') {
      return t('LogInUser')
    } else if (selectedOption === 'Email') {
      return t('LogInExample')
    } else {
      return selectedOption
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box
        id="form"
        m={0}
        sx={{
          ' > *': { marginBottom: '30px' },
          ' * > span': { visibility: 'hidden' },
        }}
      >
        {loadingError.status && <AlertWarning detail={loadingError.message} />}
        <FormControl id="document-type" isRequired>
          <SelectLogin handleSelectChange={handleSelectTypeChange} portalId={portalId} />
        </FormControl>
        <FormControl id="document-number" isRequired>
          <InputLogin
            describedby="document-number-helper"
            handleInputChange={handleDocumentChange}
            isDisabled={selectedOption ? false : true}
            placeholder={optionPlaceholderInput()}
            text={optionTextInput()}
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <InputLogin
            describedby="password-helper"
            forgotPassword
            handleInputChange={handlePasswordChange}
            isDisabled={selectedOption ? false : true}
            placeholder={t('LogInEnter')}
            text={t('LogInPassword')}
            type="password"
          />
        </FormControl>
        {loginAttempts >= 3 && (
          <FormControl id="recaptcha" isRequired>
            <Recaptcha
              verify={() => {}}
              setCaptchaToken={(token: string) => {
                setCaptcha({ verified: Boolean(token), captchaToken: token })
              }}
            />
          </FormControl>
        )}
        <Button
          backgroundColor="#0189FF"
          borderRadius="0.25rem"
          colorScheme="blue"
          fontFamily="Roboto"
          fontSize="16px"
          isDisabled={loginAttempts >= 3 && !captcha.verified}
          isLoading={loading}
          mt={4}
          size="lg"
          sx={{ marginBottom: '0px !important' }}
          type="submit"
          width="100%"
          _hover={{ backgroundColor: '#017BE5' }}
          _loading={{
            backgroundColor: '#0189FF',
            opacity: '1',
            color: 'white',
            cursor: 'wait',
          }}
          spinner={<LoaderAsync />}
        >
          {t('LogIn')}
        </Button>
      </Box>
    </form>
  )
}
